<template>
  <div>
    <b-overlay
      :show="load"
      variant="transparent"
    >
      <b-card>
        <b-row>
          <b-col md="12">
            <label for="credorName">Credor</label>
            <v-select
              id="credorName"
              v-model="credor.selected"
              label="title"
              :options="credor.credOption"
            />
          </b-col>
          <b-col
            md="8"
            class="mt-2"
          >
            <label>Período Vencimento</label>
            <b-row>
              <b-col cols="6">
                <b-form-input
                  id="vencimento-inicio"
                  v-model="vencimento.inicio"
                  locale="pt"
                  type="date"
                />
              </b-col>
              <b-col cols="6">
                <b-form-input
                  id="vencimento-final"
                  v-model="vencimento.final"
                  locale="pt"
                  type="date"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="6">
            <b-button
              variant="primary"
              :disabled="credor.selected && credor.selected.length <= 0"
              @click="extrairDados"
            >
              Baixar
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { saveAs } from 'file-saver'
import axios from '../../../axios-auth'

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    vSelect,
    BOverlay,
  },
  data() {
    return {
      credor: {
        credOption: [],
        selected: [],
        ids: [],
      },
      vencimento: {
        inicio: '',
        final: '',
      },
      load: false,
    }
  },
  created() {
    this.getCredores()
  },
  methods: {
    getCredores() {
      axios.get('api/v1/credores/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          const objeto = res.data.dados
          // eslint-disable-next-line array-callback-return
          objeto.map(dt => {
            this.credor.credOption.push({ title: dt.nome, id: dt.id })
          })
        })
    },
    async extrairDados() {
      this.load = true
      const body = {
        credor_id: this.credor.selected.id,
      }
      this.vencimento.inicio && this.vencimento.final ? body.vencimento = { vencimento_inicio: this.vencimento.inicio, vencimento_final: this.vencimento.final } : null
      axios.post('api/v1/relatorios/backoffice/extrair', body, {
        headers: {
          accept: 'application/json',
        },
        responseType: 'blob',
      }).then(res => {
        this.load = false
        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        saveAs(blob, 'relatorio.xlsx')
      })
        .catch(() => {
          this.load = false
          this.$swal({
            title: 'Error!',
            text: 'Você esqueceu de selecionar o credor',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    clearForm() {
      this.credor.selected = []
      this.vencimento.inicio = ''
      this.vencimento.final = ''
    },
  },
}
</script>
